<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "SubmissionsTheHarshRealityAndHowToImproveYourOdds",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="SubmissionsTheHarshRealityAndHowToImproveYourOdds">
      <SuawParagraph
        text="A rejection letter leaves many writers devastated. For years, I would submit one to three pieces a year to literary magazines, and if the work received a rejection, it became dead to me. My nonfiction wellness articles had a 98% acceptance rate, leading me to believe I would have no problem getting my fiction and creative nonfiction published. I did not know about the incredibly low acceptance rates of literary magazines."
      />
      <SuawParagraph
        text='There are finite spaces to fill in the literary world, though the internet itself seems infinite. Writers hoping to be published in top-tier literary magazines are faced with startlingly low acceptance rates. According to <a target="_blank" title="https://duotrope.com/" href="https://duotrope.com/">Duotrope</a>, The New Yorker magazine accepted only 0.14% of 1,447 unsolicited submissions received in a year. The lower-tier magazine Split Lip received 938 unsolicited submissions that same year, and only 0.11% were accepted.'
      />
      <SuawParagraph
        text='Rejection feels personal, though it isn’t. It’s a numbers game, even with smaller publications. To prove this, I reached out to Viva Padilla, the editor in chief of the annual literary magazine <a target="_blank" title="https://drylandla.org/" href="https://drylandla.org/">Dryland</a>, and asked her about the submission statistics for her publication. “The most submissions we have ever received [for a single issue] was over 1000,” she said. “Every issue has about 50-55 publishing spots available, with around 40 reserved for poetry.” I did the math. That equates to an acceptance rate of 1%-1.5% for a work of fiction. For poetry, the odds are slightly better at 5%. That’s a rejection rate of 95-99%. The acceptance rate is higher compared to Split Lip and The New Yorker, but even then most of the work is rejected due to space limitations, among other reasons.'
      />
      <SuawParagraph
        text='The work Dryland publishes is primarily through open calls. Most literary magazines and journals solicit work from writers they know or those with name recognition, presenting an entry barrier to emerging or unrepresented writers. In her 2015 article for The Atlantic, acclaimed writer Joy Lanzendorfer made two interesting assertions. First, that the average published story is likely rejected 20 times before being published; second, that  <a target="_blank" title="https://www.writersdigest.com/getting-published/what-is-the-slush-pile" href="https://www.writersdigest.com/getting-published/what-is-the-slush-pile">slush pile</a> submissions only account for 1-2% of published work. Simply put, most of the work you see in the literary spaces is based on connection or writer recognition. Despite the rejection stats, and the reality of how many times it takes for a story to stick to a magazine’s pages, this emphasizes the importance of being an active member of the writing community. Editors solicit work from people they know. To be known you have to be an active member of the literary community.'
      />
      <SuawParagraph
        text="A few years ago, I became a member of Women Who Submit (WWS), a nonprofit focused on elevating the voices of BIPOC, women, and nonbinary writers. This organization helped me see behind the literary curtain. Various members have taken time to offer me guidance and mentorship through both the submission and rejection process. WWS showcases the importance of relationships and elevating each other’s voices. The reality is that writers who know writers get published more, and all editors of literary magazines are writers themselves."
      />
      <SuawParagraph
        text="Since having my eyes opened by WWS, I have become a member of other writing communities, including Shut Up and Write. Outside of my WWS bubble, I have seen that other writers in various spaces struggle with the inevitability of rejection like I did. I hope this helps reduce the sting of rejection, and I would encourage you to submit again and again until your work finds its literary home."
      />
      <SuawParagraph
        text='Recently, I received a really nice standard rejection from <a target="_blank" title="https://www.zyzzyva.org/" href="https://www.zyzzyva.org/">Zyzzyva</a>. The last paragraph said, “I would like to say something to make up for this ungraciousness, but the truth is we have so little space, we must return almost all the work that is submitted, including a great deal that interests us and even some pieces we admire. Inevitably, too, we make mistakes.”'
      />
      <SuawParagraph
        text='I asked Christopher James, the editor in chief of the five-year-old <a target="_blank" title="https://jellyfishreview.wordpress.com/" href="https://jellyfishreview.wordpress.com/">Jellyfish Review</a>, how often his magazine rejects stories they love because of lack of space. He responded, “We frequently say no to a lot of very strong stories, we would never say no to something we loved. We often accept imperfect pieces because we’ve fallen in love with them and hope our readers might fall in love with them too.” As the newer journal matures, they too may have to say no to work they admire. At the moment though, if they read your work and love it they’ll make space for it. Most journals have a limitation and only accept a certain amount of work—Jellyfish is the exception, not the rule.'
      />
      <SuawParagraph
        text="Rejection is part of the process of getting your work out into the world. Should you default to younger journals? You can, but I believe that as a writer you should submit to the journals that speak to you, the magazines that you envision as a perfect fit. Familiarize yourself with the work they publish, follow their submission guidelines, and keep submitting work that aligns with their aesthetic. It will increase your chances of acceptance."
      />
      <SuawParagraph
        text="Even though stats are against them, many writers still manage to succeed, and you could be one of them. I reached out to the writers I know and asked if anyone had received multiple rejections only for a piece to be published later. Carla Sameth, author of “One Day on the Goldline: A Memoir in Essays” shared that her personal essay, “If This Is So, Why Am I?” was rejected 22 times before it was published in The Nervous Breakdown, only to be selected as notable for The Best American Essays of 2019. Just because something receives multiple rejections does not mean it isn’t worthy of recognition, accolades, or publishing. It left me wondering if those other 22 magazine editors felt they missed their window and had inevitably made a mistake."
      />
      <SuawParagraph
        text="I asked Kate Maruyama, the author of my favorite novella of 2020, “Family Solstice,” about her experience. Eighteen editors rejected her first novel “Harrowgate” before it was purchased. A short story of hers was rejected 35 times before being published. “For the short story, rejection number 10 was from Roxane Gay when she was reading for a journal,” Maruyama explained. “She said it wasn’t right for that journal but that it was a damn fine story.” Those words of encouragement along with her writing community kept Maruyama submitting."
      />
      <SuawParagraph
        text="To improve your odds and to keep a stiff upper lip when rejection inevitably finds its way to your inbox, here are some pointers to help ease the painful experience:"
      />
      <SuawParagraph
        text="<strong>Take resubmission requests seriously.&nbsp;</strong>If a magazine rejects a written piece of yours but asks you to resubmit, they are not being nice. They don’t have time to be nice. They enjoy your work! Resubmit."
      />
      <SuawParagraph
        text="<strong>Familiarize yourself with the places you want to submit.</strong> If editors keep telling you that your work doesn’t fit their publication, read the publication. If you can’t access the publication because of monetary restrictions, look for copies in your local library or read the work of the writers who have recently been published by the magazine or journal. A Google search of the author will find other work of theirs that you can read for free. Compare their aesthetic to your own. Are you a fit? Then it’s a good place to submit!"
      />
      <SuawParagraph
        text="<strong>Make sure your work is submission-ready.&nbsp;</strong>This is the number one sin of writers, according to Viva Padilla. <a target='_blank' href='https://joylandmagazine.com/''>Dryland</a> doesn’t edit poetry; “…we expect poems to be ready to go,” she said. “When it comes to fiction and nonfiction that gets rejected, it’s mostly work that doesn’t seem to have a focus where we’re left wondering, what was that about?” Workshop your work with other writers and make sure to check your grammar before you submit."
      />
      <SuawParagraph
        text="<strong>Don’t expect an editor to provide feedback.</strong> If a magazine rejected you without giving a reason, pestering an editor for the “why” will quickly slam doors on future opportunities. Personalized rejections are rare. They are nice when they come in, but an editor doesn’t owe you a reason."
      />
      <SuawParagraph
        text="The reality is slush piles at literary magazines are immense, and many editors are volunteers or minimally compensated. These magazines are mostly labors of love for the written word. Rejection may seem personal, but it’s not. Even literary magazine editors get rejections from other publications. The more you submit, the greater the chance your work will find a literary home. The more time you take to prepare and research the best market for your work, the greater your odds for acceptance. Don’t be discouraged when you get a no. Look over your work and see if there are any structural or grammatical issues. If not, submit it again ASAP. If there are errors, fix them and send your piece out again."
      />
      <SuawParagraph text="Bottom line: it’s time to Shut Up &amp; Submit!" />
    </ArticleFrame>
  </SuawMainContent>
</template>
